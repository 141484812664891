<template>
  <div class="top-pattern d-flex justify-space-between align-items-center">
    <div class="d-flex flex-direction-column align-items-center">
      <img src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/0d4e494e3fdcb424184ec4e70590c0f7.png" alt="" height="30px">
    </div>
    <div class="d-flex justify-space-between align-items-center">
      <div v-if="userAccount">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ userAccount }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">{{ $t('base.loginOut') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <div class="mar-left-50">
          <el-dropdown @command="handleCommandLanguage">
            <span class="el-dropdown-link">
              {{ $t('language.language') }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh_CN">{{ $t('language.chinese') }}</el-dropdown-item>
              <el-dropdown-item command="en_US">{{ $t('language.english') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      userAccount: this.$store.state.userInfo,
    }
  },
  mounted(){
    console.log(this.userAccount)
  },
  methods: {
    handleCommand() {
      sessionStorage.clear();
      this.$store.commit('setToken', '')
      this.$store.commit('setInfo', '');//更新userInfo
      this.$router.push({
        path: '/login'
      })
    },
    // 切换语言
    handleCommandLanguage(command) {
      if (command === "zh_CN") {
        Cookies.set('language', 'zh_CN')
        this.$i18n.locale = 'zh_CN'
      } else {
        Cookies.set('language', 'en_US')
        this.$i18n.locale = 'en_US'
      }
      this.$router.go(0);
    },
  }
}
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  /* color: #409eff; */
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
