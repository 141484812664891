<template>
  <div>
    <top-Application></top-Application>
    <router-view style="min-height: 95vh" />
    <bottomApplication></bottomApplication>
  </div>
</template>
<script>
import topApplication from '@/components/topApplication.vue'
import bottomApplication from '@/components/bottomApplication.vue'
export default {
  components: {
    topApplication,
    bottomApplication,
  }
}
</script>
